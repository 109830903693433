<template>
  <div class="login-view">
    <div class="" v-if="show === 'login'">
      <login-component
        @on-success="onLoginSuccess"
        @on-register-click="onRegisterClick"
      />
    </div>

    <div class="" v-if="show === 'register'">
      <register-component
        @on-success="onLoginSuccess"
        @on-login-click="onLoginClick"
      />
    </div>
  </div>
</template>

<script>
import LoginComponent from "./login-component.vue";
import RegisterComponent from "./regist-component.vue";

export default {
  name: "Home",
  components: {
    LoginComponent,
    RegisterComponent,
  },
  data: () => ({
    show: "login",
  }),
  methods: {
    init() {},
    // 登录成功后
    onLoginSuccess() {
      const { from } = this.$route.query;
      if (from) {
        window.location.href = from;
      } else {
        this.$router.push({ path: "/" });
      }
    },
    // 点击登录按钮时
    onLoginClick() {
      this.show = "login";
    },
    onRegisterClick() {
      this.show = "register";
    },
  },
};
</script>

<style>
body {
  width: 100%;
  height: 100%;
}
.home {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
