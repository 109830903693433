<template>
  <div class="home">
    <video
      class="video-bg"
      v-if="showVideoBg"
      loop="loop"
      autoplay="autoplay"
      muted="muted"
    >
      <source :src="src" type="video/mp4" />
    </video>

    <div class="login-comp">
      <login-view></login-view>
    </div>
  </div>
</template>

<script>
import LoginView from "./login-view.vue";
import { LOGIN_BACKGROUND_VIDEO } from "../../config/index";
import { isSmartDevice } from "../../utils/device";

export default {
  name: "Home",
  components: {
    LoginView,
  },
  data: () => ({
    src: LOGIN_BACKGROUND_VIDEO,
  }),
  computed: {
    showVideoBg() {
      return !isSmartDevice();
    },
  },
  methods: {
    init() {},
  },
};
</script>

<style type="css" scoped>
.home {
  display: block;
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(https://img01.wanfangche.com/product/uni/activity/019.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
